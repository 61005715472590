div .amplify-button {
  color: #3399ff;
}

div .amplify-button:hover {
  background-color: #42424250;
  color: #fff;
}

div .amplify-button:focus {
  background-color: transparent;
  color: rgba(16, 86, 245, 0.6);
}

.text-animation {
  text-align: center;
  color: #fff;
  vertical-align: middle;
  margin-top: 50px;
  font-size: 7vw;
}

.text-animation-small {
  text-align: center;
  color: #fff;
  vertical-align: middle;
  font-size: 4vw;
}

/* div .scroll-view {
  border-style: solid;
  border-radius: 2px;
  border-color: #fff;
  border-width: 1px;
  margin: 20px;
  color: #fff;
  font-size: 30px;
  overflow-y: scroll;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

.restaurant-text {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

div .amplify-text {
  color: #fff;
  border-style: solid;
  border-radius: 10px;
  border-color: #fff;
  border-width: 1px;
  margin: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 20px;
  padding-right: 20px;
}

.rounded-background {
  border-radius: 40px !important;
  background: #424242 !important;
  margin: 20px !important;
}

a:link {
  color: #3399ff;
}

a:visited {
  color: #ff0000;
}

.row {
  display: flex;
  flex-direction: row;
  width: auto;
}

.flex-container {
  width: auto;
  padding: 0;
  margin: 10px;
  flex-direction: column;
  display: flex;
  border: 1px solid white;
  border-radius: 10px;
  background: #424242;
}

.footer {
  margin-top: 1rem;
  padding: 1rem;
  background-color: #424242f9;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

.wing-image:hover {
  cursor: pointer;
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
  transition: 0.5s ease;
}
